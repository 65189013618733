/* General */
.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.div-block-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}

.paragraph {
  font-family: Gilroy, sans-serif;
}

.header-link {
  color: #000;
  text-decoration: none;
}

.header-link:hover {
  color: #0d6191;
  text-decoration: none;
}

.link {
  color: #51b5ed;
  text-decoration: none;
}

.link:hover {
  color: #0d6191;
  text-decoration: none;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 24px;
  margin: 48px 0 0 0;
}

#all-clicked {
  color: #fff;
  background-color: #b9d9eb;
}

#all-unclicked {
  border: 1px solid #b9d9eb;
  color: #b9d9eb;
  background-color: #fff;
}

.cards {
  display: flex;
  justify-content: space-around;
}

.wrap {
  flex-wrap: wrap;
}

.card {
  display: flex;
  width: 100%;
  max-width: 500px;
  padding: 0 2.5%;
  border-radius: 5%;
  margin: 2.5%;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  transition: 0.5s;
}

.card:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
}

img {
  margin: 15px auto 15px auto;
}

iframe[src*="youtube"] {
  max-width: 100%;
  margin: 15px auto 15px auto;
}

.hidden {
  display: none;
  width: 0px;
  height: 0px;
}

/* jclvsh */
#apps-clicked {
  color: #fff;
  background-color: #ff5733;
}

#apps-unclicked {
  border: 1px solid #ff5733;
  color: #ff5733;
  background-color: #fff;
}

#ai-clicked {
  color: #fff;
  background-color: #03adfc;
}

#ai-unclicked {
  border: 1px solid #03adfc;
  color: #03adfc;
  background-color: #fff;
}

#fintech-clicked {
  color: #fff;
  background-color: #c5c749;
}

#fintech-unclicked {
  border: 1px solid #c5c749;
  color: #c5c749;
  background-color: #fff;
}

#ecommerce-clicked {
  color: #fff;
  background-color: #c74980;
}

#ecommerce-unclicked {
  border: 1px solid #c74980;
  color: #c74980;
  background-color: #fff;
}

#defi-clicked {
  color: #fff;
  background-color: #658ecf;
}

#defi-unclicked {
  border: 1px solid #658ecf;
  color: #658ecf;
  background-color: #fff;
}

#nfts-clicked {
  color: #fff;
  background-color: #6dc37c;
}

#nfts-unclicked {
  border: 1px solid #6dc37c;
  color: #6dc37c;
  background-color: #fff;
}

#daos-clicked {
  color: #fff;
  background-color: #946dc3;
}

#daos-unclicked {
  border: 1px solid #946dc3;
  color: #946dc3;
  background-color: #fff;
}

#gaming-clicked {
  color: #fff;
  background-color: #ad3baf;
}

#gaming-unclicked {
  border: 1px solid #ad3baf;
  color: #ad3baf;
  background-color: #fff;
}

#ethereum-clicked {
  color: #fff;
  background-color: #215caf;
}

#ethereum-unclicked {
  border: 1px solid #215caf;
  color: #215caf;
  background-color: #fff;
}

#polygon-clicked {
  color: #fff;
  background-color: #66095a;
}

#polygon-unclicked {
  border: 1px solid #66095a;
  color: #66095a;
  background-color: #fff;
}

#bsc-clicked {
  color: #fff;
  background-color: #f0b90b;
}

#bsc-unclicked {
  border: 1px solid #f0b90b;
  color: #f0b90b;
  background-color: #fff;
}

#solana-clicked {
  color: #fff;
  background-color: #03e1ff;
}

#solana-unclicked {
  border: 1px solid #03e1ff;
  color: #03e1ff;
  background-color: #fff;
}

#terra-clicked {
  color: #fff;
  background-color: #172852;
}

#terra-unclicked {
  border: 1px solid #172852;
  color: #172852;
  background-color: #fff;
}

#flow-clicked {
  color: #fff;
  background-color: #00ef8b;
}

#flow-unclicked {
  border: 1px solid #00ef8b;
  color: #00ef8b;
  background-color: #fff;
}

.card-link {
  text-decoration: none;
}

.card-link:hover {
  text-decoration: none;
}

#open-book-club-logo {
  width: 250px;
  height: 50px;
}

#mogle-logo {
  width: 125px;
  height: 50px;
}

#thirdwave-logo {
  width: 125px;
  height: 100px;
}

#metaphy-logo {
  width: 125px;
  height: 100px;
}

#slant-logo {
  width: 100px;
  height: 50px;
}

/* Ynho */
#original-clicked {
  color: #fff;
  background-color: #e49b6d;
}

#original-unclicked {
  border: 1px solid #e49b6d;
  color: #e49b6d;
  background-color: #fff;
}

#covers-clicked {
  color: #fff;
  background-color: #af64b0;
}

#covers-unclicked {
  border: 1px solid #af64b0;
  color: #af64b0;
  background-color: #fff;
}

#piano-clicked {
  color: #fff;
  background-color: #e17ac4;
}

#piano-unclicked {
  border: 1px solid #e17ac4;
  color: #e17ac4;
  background-color: #fff;
}

#acoustic-clicked {
  color: #fff;
  background-color: #b663d7;
}

#acoustic-unclicked {
  border: 1px solid #b663d7;
  color: #b663d7;
  background-color: #fff;
}

#electric-clicked {
  color: #fff;
  background-color: #c33d3d;
}

#electric-unclicked {
  border: 1px solid #c33d3d;
  color: #c33d3d;
  background-color: #fff;
}

#production-clicked {
  color: #fff;
  background-color: #d2ee96;
}

#production-unclicked {
  border: 1px solid #d2ee96;
  color: #d2ee96;
  background-color: #fff;
}

#djing-clicked {
  color: #fff;
  background-color: #3d83ac;
}

#djing-unclicked {
  border: 1px solid #3d83ac;
  color: #3d83ac;
  background-color: #fff;
}

.empty {
  width: 560px;
}

.youtube {
  width: 560px;
  height: 315px;
}

/* CaughtYouIn8K */
#caughtyouin8k {
  width: 100%;
  height: 100%;
}

/* Mobile */
@media screen and (max-width: 700px) {
  .cards {
    flex-wrap: wrap;
  }

  .youtube {
    width: 50%;
  }

  .empty {
    width: 50%;
  }
}
